import { mapState } from 'vuex'
import SContainer from '../../components/ui/s-container'
import SLink from '../../components/ui/s-link'
import SButton from '../../components/ui/s-button'

import ResetPassword from './components/ResetPassword'

import backIcon from '../../assets/images/icone_voltar_simples.svg'

export default {
  name: 'Settings',
  components: {
    SContainer,
    SLink,
    SButton,
    ResetPassword,
  },

  data: () => ({
    backIcon,
    alert: null,
    showAlert: false,
    alertStyle: '',
  }),

  computed: mapState(['username', 'error', 'notice']),

  watch: {
    notice() {
      if (this.notice) {
        this.alert = this.notice
        this.showAlert = true
        this.alertStyle = ''
      }
    },
    error() {
      if (this.error) {
        this.alert = this.error
        this.showAlert = true
        this.alertStyle = 'bg-error'
      }
    },
  },
}
