import { mapActions } from 'vuex'
import SButton from '../../../../components/ui/s-button'
import Password from 'vue-password-strength-meter'

export default {
  name: 'ResetPassword',
  components: {
    SButton,
    Password,
  },

  data() {
    return {
      score: 0,
      old_password: '',
      password: '',
      valid: false,
    }
  },
  methods: {
    ...mapActions(['updateCredentials']),

    setScore(score) {
      this.score = score
      this.valid = this.score >= 3
    },

    save() {
      if (!!this.old_password && this.valid) {
        const payload = {
          old_password: this.old_password,
          password: this.password,
        }
        this.updateCredentials(payload)
        this.reset()
      } else {
        this.$store.commit('setError', 'Forneça uma senha forte e segura!')
      }
    },

    reset() {
      this.score = 0
      this.old_password = ''
      this.password = ''
      this.valid = false
    },
  },
}
